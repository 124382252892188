
//setzen eines default produktlogos, nicht in wie die Farben in der body klasse, damit vor Abruf der produktInfos kein falsches Logo angezeigt wird.
.branding-default {
  --product_logo_path: url('/assets/images/brandings/famoca.svg');
}

/* Brandings:
6b057afb-b246-57b3-999c-d81efc765e61    iCIS
d8870833-355c-567e-8002-bafb59e8ee3e    EniMon
32be8115-1f70-5d72-83bc-4aab924d0cda    EnviMon
d945c44d-24a9-5c61-a2df-ce80ef4dab11    GateOn
ebbc5c03-e3f8-53ee-9a31-d97e2b906485    EniGate
*/

/* GateOn Branding */

.validate:invalid {
  border-color: #a94442;
}

.branding-ebbc5c03-e3f8-53ee-9a31-d97e2b906485  {

  --color1: hsl(0, 0%, 92%);
  --color1_contrast: black;
  --color1_gradient: hsl(0, 0%, 92%);
  --color1_darker: #ddd;
  --color2: #005d8a;
  --color2_contrast: white;
  --color3: #c4cdaa;
  --color3_contrast: black; //needs to contrast with color1 as well

  --defaultBackground: white;
  --defaultText: black;


  //Tables:
  --tableHeaderBackground: var(--color2);
  --tableHeaderText: var(--color2_contrast);
  --tableBorder: var(--color2);
  --tableActiveOutline: var(--color3);
  --tableDivider: hsla(0, 0%, 50%, 0.1);
  --tableRowBackgroundOdd: var(--color1);
  --tableRowBackgroundActive: var(--color3);
  --tableRowText: var(--color1_contrast);

  //Cards:
  --cardText: var(--defaultText);
  --cardBackground: var(--defaultBackground);
  --cardHeaderBackground: var(--color1_gradient);
  --cardHeaderBackground2: var(--color1);
  --cardHeaderText: var(--color1_contrast);
  --cardHeaderTextHighlight:  var(--color2);
  --cardFooterBackground:  var(--color1);
  --cardBorder:  var(--color1_darker);

  //Navbar:
  --navbarBackground: var(--color1);
  --navbarBackgroundHighlight: var(--color1_darker);
  --navbarText: var(--color1_contrast);
  --navbarTextHighlight: var(--color1_contrast);
  --product_logo_path: url('/assets/images/brandings/enigate.svg') !important;
}

/* GateOn Branding */
.branding-d945c44d-24a9-5c61-a2df-ce80ef4dab11 {

  --color1: hsl(0, 0%, 92%);
  --color1_contrast: black;
  --color1_gradient: hsl(0, 0%, 92%);
  --color1_darker: #ddd;
  --color2: #005d8a;
  --color2_contrast: white;
  --color3: #c4cdaa;
  --color3_contrast: black; //needs to contrast with color1 as well

  --defaultBackground: white;
  --defaultText: black;


  //Tables:
  --tableHeaderBackground: var(--color2);
  --tableHeaderText: var(--color2_contrast);
  --tableBorder: var(--color2);
  --tableActiveOutline: var(--color3);
  --tableDivider: hsla(0, 0%, 50%, 0.1);
  --tableRowBackgroundOdd: var(--color1);
  --tableRowBackgroundActive: var(--color3);
  --tableRowText: var(--color1_contrast);

  //Cards:
  --cardText: var(--defaultText);
  --cardBackground: var(--defaultBackground);
  --cardHeaderBackground: var(--color1_gradient);
  --cardHeaderBackground2: var(--color1);
  --cardHeaderText: var(--color1_contrast);
  --cardHeaderTextHighlight:  var(--color2);
  --cardFooterBackground:  var(--color1);
  --cardBorder:  var(--color1_darker);

  //Navbar:
  --navbarBackground: var(--color1);
  --navbarBackgroundHighlight: var(--color1_darker);
  --navbarText: var(--color1_contrast);
  --navbarTextHighlight: var(--color1_contrast);
  --product_logo_path: url('/assets/images/brandings/gateon.svg') !important;
}

/* EnViMon Branding */
.branding-32be8115-1f70-5d72-83bc-4aab924d0cda {

  --color1: hsl(0, 0%, 92%);
  --color1_contrast: black;
  --color1_gradient: hsl(206, 20%, 97%);
  --color1_darker: #ddd;
  --color2: hsl(125, 100%, 28%);
  --color2_contrast: white;
  --color3: #c4cdaa;
  --color3_contrast: black; //needs to contrast with color1 as well

  --defaultBackground: white;
  --defaultText: black;


  //Tables:
  --tableHeaderBackground: var(--color2);
  --tableHeaderText: var(--color2_contrast);
  --tableBorder: var(--color2);
  --tableActiveOutline: var(--color3);
  --tableDivider: hsla(0, 0%, 50%, 0.1);
  --tableRowBackgroundOdd: var(--color1);
  --tableRowBackgroundActive: var(--color3);
  --tableRowText: var(--color1_contrast);

  //Cards:
  --cardText: var(--defaultText);
  --cardBackground: var(--defaultBackground);
  --cardHeaderBackground: var(--color1_gradient);
  --cardHeaderBackground2: var(--color1);
  --cardHeaderText: var(--color1_contrast);
  --cardHeaderTextHighlight:  var(--color2);
  --cardFooterBackground:  var(--color1);
  --cardBorder:  var(--color1_darker);

  //Navbar:
  --navbarBackground: var(--color1);
  --navbarBackgroundHighlight: var(--color1_darker);
  --navbarText: var(--color1_contrast);
  --navbarTextHighlight: var(--color1_contrast);
  --product_logo_path: url('/assets/images/brandings/envimon.svg') !important;
}

/* EniMon Branding */
.branding-d8870833-355c-567e-8002-bafb59e8ee3e {

  --color1: hsl(0, 0%, 92%);
  --color1_contrast: black;
  --color1_gradient: hsl(206, 20%, 97%);
  --color1_darker: #ddd;
  --color2: hsl(187, 87%, 63%);
  --color2_contrast: black;
  --color3: #c4cdaa;
  --color3_contrast: black; //needs to contrast with color1 as well

  --defaultBackground: white;
  --defaultText: black;


  //Tables:
  --tableHeaderBackground: var(--color2);
  --tableHeaderText: var(--color2_contrast);
  --tableBorder: var(--color2);
  --tableActiveOutline: var(--color3);
  --tableDivider: hsla(0, 0%, 50%, 0.1);
  --tableRowBackgroundOdd: var(--color1);
  --tableRowBackgroundActive: var(--color3);
  --tableRowText: var(--color1_contrast);

  //Cards:
  --cardText: var(--defaultText);
  --cardBackground: var(--defaultBackground);
  --cardHeaderBackground: var(--color1_gradient);
  --cardHeaderBackground2: var(--color1);
  --cardHeaderText: var(--color1_contrast);
  --cardHeaderTextHighlight:  var(--color2);
  --cardFooterBackground:  var(--color1);
  --cardBorder:  var(--color1_darker);

  //Navbar:
  --navbarBackground: var(--color1);
  --navbarBackgroundHighlight: var(--color1_darker);
  --navbarText: var(--color1_contrast);
  --navbarTextHighlight: var(--color1_contrast);
  --product_logo_path: url('/assets/images/brandings/enimon.svg') !important;
}

/* iCIS Branding */
.branding-6b057afb-b246-57b3-999c-d81efc765e61 {

  --color1: hsl(0, 0%, 92%);
  --color1_contrast: black;
  --color1_gradient: hsl(206, 20%, 97%);
  --color1_darker: #ddd;
  --color2: hsl(11, 87%, 63%);
  --color2_contrast: white;
  --color3: #c4cdaa;
  --color3_contrast: black; //needs to contrast with color1 as well

  --defaultBackground: white;
  --defaultText: black;


  //Tables:
  --tableHeaderBackground: var(--color2);
  --tableHeaderText: var(--color2_contrast);
  --tableBorder: var(--color2);
  --tableActiveOutline: var(--color3);
  --tableDivider: hsla(0, 0%, 50%, 0.1);
  --tableRowBackgroundOdd: var(--color1);
  --tableRowBackgroundActive: var(--color3);
  --tableRowText: var(--color1_contrast);

  //Cards:
  --cardText: var(--defaultText);
  --cardBackground: var(--defaultBackground);
  --cardHeaderBackground: var(--color1_gradient);
  --cardHeaderBackground2: var(--color1);
  --cardHeaderText: var(--color1_contrast);
  --cardHeaderTextHighlight:  var(--color2);
  --cardFooterBackground:  var(--color1);
  --cardBorder:  var(--color1_darker);

  //Navbar:
  --navbarBackground: var(--color1);
  --navbarBackgroundHighlight: var(--color1_darker);
  --navbarText: var(--color1_contrast);
  --navbarTextHighlight: var(--color1_contrast);
  --product_logo_path: url('/assets/images/brandings/icis.svg') !important;
}

body {
  margin: 8px !important; /* Bootstrap: 0px */

  /* StandardFarben: */

  --color1: hsl(0, 0%, 92%);
  --color1_contrast: black;
  --color1_gradient: hsl(206, 20%, 97%);
  --color1_darker: #ddd;
  --color2: gray;
  --color2_contrast: white;
  --color3: #c4cdaa;
  --color3_contrast: black; //needs to contrast with color1 as well

  --defaultBackground: white;
  --defaultText: black;


  //Tables:
  --tableHeaderBackground: var(--color2);
  --tableHeaderText: var(--color2_contrast);
  --tableBorder: var(--color2);
  --tableActiveOutline: var(--color3);
  --tableDivider: hsla(0, 0%, 50%, 0.1);
  --tableRowBackgroundOdd: var(--color1);
  --tableRowBackgroundActive: var(--color3);
  --tableRowText: var(--color1_contrast);

  //Cards:
  --cardText: var(--defaultText);
  --cardBackground: var(--defaultBackground);
  --cardHeaderBackground: var(--color1_gradient);
  --cardHeaderBackground2: var(--color1);
  --cardHeaderText: var(--color1_contrast);
  --cardHeaderTextHighlight:  var(--color2);
  --cardFooterBackground:  var(--color1);
  --cardBorder:  var(--color1_darker);

  //Navbar:
  --navbarBackground: var(--color1);
  --navbarBackgroundHighlight: var(--color1_darker);
  --navbarText: var(--color1_contrast);
  --navbarTextHighlight: var(--color1_contrast);


  //--product_logo_path: url('/assets/images/brandings/famoca.svg') via default-branding Klasse, um Logo-Flackern zu vermeiden

}

footer {
  position: fixed;
  bottom: 0;
  height: 25px;
  padding-bottom: 4px;
  background-color: var(--cardFooterBackground);
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.legal-notice {
  background-color: var(--navbarBackground);
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
}

body.kiosk {
  margin: 0 !important;
}

.last-row-border tr:last-child {
  border-top: 1px solid var(--tableBorder);
}

.famoca-navbar{
  background-color: var(--navbarBackground);
  padding: 0px;
}

.nav-item.dropdown.show {
  background-color: var(--navbarBackgroundHighlight);

  > a {
    color: var(--navbarTextHighlight) !important;
  }
}
.nav-item > .dropdown-menu > .dropdown-item.active {
  background-color: var(--navbarBackgroundHighlight) !important;

  color: var(--navbarTextHighlight);
}
.nav-item > .dropdown-menu > .dropdown-item:active {
  background-color: var(--navbarBackgroundHighlight) !important;

  color: var(--navbarTextHighlight);
}

.famoca-card{
  border-color: var(--cardBorder);
  background-color: var(--cardBackground);
  > .card-header:not(.alert-success):not(.alert-danger) {
    background: linear-gradient(var(--cardHeaderBackground), var(--cardHeaderBackground2));
    padding-bottom: 0;
    border-bottom: 1px solid var(--cardBorder);
    background-color: var(--cardHeaderBackground);
    color: var(--cardHeaderText);
    > .nav > .nav-item > .nav-link {
      margin-left: 1px;
      border: 1px solid var(--cardBorder);
      background-color: var(--cardFooterBackground);
      color: var(--cardHeaderText);
      &.active {
        border-top: 1px solid var(--cardBorder);
        border-left: 1px solid var(--cardBorder);
        border-right: 1px solid var(--cardBorder);
        color: var(--cardText);
        border-bottom: 1px solid var(--cardBackground);
        background-color: var(--cardBackground);
      }
    }
  }
  > .card-footer:not(.alert-success):not(.alert-danger) {
    border-color: var(--cardBorder);
    background-color: var(--cardFooterBackground);
  }
}

.famoca-card-header-text-highlight{
  /*font-weight: bold;*/
  color: var(--cardHeaderTextHighlight)
}



.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.c3-axis-x-label,
.c3-axis-y-label,
.c3-axis-y2-label {
  font-size: 120%;
}

.c3-axis-x .tick {
  line:has(~ text[style*="display: none"]),
  line:has(~ text[style*="display:none"])
  {
    visibility: hidden;
  }
}

.enimon {
  font-style: italic
}

.highlight-blue {
  font-weight: bold;
  color: var(--cardHeaderTextHighlight) !important;
}

.highlight {
  font-weight: bold;
  color: var(--textHighlight) !important;
}

.famoca-menu-divider {
  display: flex;
  align-items: center;
  text-align: center;
}

.famoca-menu-divider:before {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--textMuted);
  margin-left: 0px;
  margin-right: 5px;
}

.famoca-menu-divider:after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--textMuted);
  margin-right: 0px;
  margin-left: 5px;
}



.famoca-menu-divider-text {
  font-size: small;
  color: var(--textMuted);
}

/* link-tos in Bootstrap Navigationsleiste, Breadcrumb */
.breadcrumb a.active,
.dropdown a.active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  outline: none;
}

.strike {
  text-decoration: line-through
}

tr.deleted > * {
  color: gray !important;
  background-color: lightgray !important;

}

.italic {
  font-style: italic;
}

a {
  cursor: pointer;
}

a:focus {
  text-decoration: none;
  outline: none;
  color: inherit;
}

.caret.up {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}

.datagrid table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.datagrid {
  font: normal 12px/150% Arial, Helvetica, sans-serif;
  overflow-x: auto;
  border: 1px solid var(--tableBorder);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  isolation: isolate;
}

.datagrid caption {
  color: black;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  caption-side: top;
}

.datagrid table td, .datagrid table th {
  padding: 3px 10px;
}

/* looks bad
.datagrid table td .highlight {

	background: rgba(100, 100, 100, 0.2) none repeat scroll 0 0;
	border-radius: 1px;
}
*/
.datagrid table thead th {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006699',
    endColorstr='#00557F');
  background-color: var(--tableHeaderBackground);
  color: var(--tableHeaderText);
  font-size: 15px;
  font-weight: bold;
  border-left: 1px solid var(--tableBorder);
  border-bottom: 1px solid var(--tableBorder);
  position: sticky;
  top: 0;
  z-index: 1;
}

.datagrid table thead th:first-child {
  border-left: none;
}

.datagrid table tbody td {
  color: var(--tableRowText);
  border-left: 1px solid var(--tableDivider);
  font-size: 12px;
  font-weight: normal;
}

.datagrid table tbody tr:not(.active).actionlink {

  cursor: pointer;
}

.datagrid table tbody tr.actionlink.active {

  outline: var(--tableActiveOutline) solid 1px;
}

.datagrid table tbody tr.actionlink.active td.active,
.datagrid table tbody tr.actionlink.active td.active-when-row-active {

  background: var(--tableRowBackgroundActive);
}

.datagrid table td.danger {
  color: red !important;
}

.clickable {
  cursor: pointer;
}

.details-box {
  border-width: 2px;
  border-color: #428bca;
  border-style: solid;
  border-radius: 7px;
  padding: 1em;
  margin-top: 2rem;

  box-shadow: none;
}

.details-box.green {
  border-color: green;
  border-width: 3px;
  box-shadow: none;
}

.details-box.red {
  border-color: red;
  border-width: 3px;
  box-shadow: none;
}

.details-box.seamless {

  margin-top: 0;
}

.details-box-borderless {
  border-width: 2px;
  border-style: hidden;
  border-radius: 7px;
  padding: 1em;
  margin-top: 2em;
}

div.famoca-inline-block-component {

  display: inline-block;
  white-space: nowrap;
}

div.famoca-selected-template-display {

  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.famoca-small-feedback {
  background-color: green;
  color: lightgreen;
  font-size: small;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

}

input.famoca-small-feedback {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #eaeaea;
}

#measurement-unit-meters {

  border-color: #5cb85c;
}

#active-meter-details {
  outline-width: 2px;
  outline-color: green;
  outline-style: solid;
}

.datagrid table tbody .text-center {
  text-align: center;
}

.datagrid table tbody tr:nth-child(odd) {
  background-color: var(--tableRowBackgroundOdd);
  color: var(--tableRowText)
}
.datagrid table tbody tr:nth-child(even) {
  color: var(--tableRowText)
}

.datagrid table tbody td:first-child {
  border-left: none;
}

.datagrid table tbody tr:last-child td {
  border-bottom: none;
}

.datagrid table tfoot tr td {
  border-top: 1px solid var(--tableBorder);
  background-color: var(--tableRowBackgroundOdd);
}

.datagrid table.all-rules-index-table tr.expandable-table-group-row {

  cursor: pointer;
}

.datagrid table.all-rules-index-table tr.expandable-table-group-row > td {

  background-color: #f5f5f5;
  font-size: larger;
  font-weight: bold;
}

.famoca-active-alarms-container {

  height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  contain: layout paint size;
}

table.active-alarms {

  table-layout: fixed;
  max-width: 100%;
}

table.active-alarms tbody td {
  border-left: 1px solid grey;
  border-bottom: 1px solid grey;
  color: black;
  font-size: small;
}

table.active-alarms a {
  color: black;
}

table.active-alarms {
  thead th {
    position: sticky;
    top: 0;
  }

  thead th, tbody td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

table.active-alarms tbody tr td:nth-last-of-type(1) {
  text-align: center;
}

table.active-alarms tbody tr td:nth-of-type(1) {
  text-align: center;
}

table.active-alarms tr.famoca-event-critical.come {
  background-color: rgb(255, 85, 76);
}

table.active-alarms tr.famoca-event-critical.gone {
  background-color: rgba(255, 85, 76, 0.6);
}

table.active-alarms tr.famoca-event-error.come {
  background-color: rgb(255, 85, 76);
}

table.active-alarms tr.famoca-event-error.gone {
  background-color: rgba(255, 85, 76, 0.6);
}

table.active-alarms tr.famoca-event-warning.come {
  background-color: rgb(255, 160, 76);
}

table.active-alarms tr.famoca-event-warning.gone {
  background-color: rgba(255, 160, 76, 0.6);
}

table.active-alarms tr.famoca-event-message.come {
  background-color: rgb(54, 175, 186);
}

table.active-alarms tr.famoca-event-message.gone {
  background-color: rgba(54, 175, 186, 0.6);
}

table.active-alarms tr.famoca-event-service.come {
  background-color: rgb(54, 175, 186);
}

table.active-alarms tr.famoca-event-service.gone {
  background-color: rgba(54, 175, 186, 0.6);
}

#measurement-unit-details dl {
  width: 20%;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
}

#measurement-unit-details dt {
  font-weight: bold;
  font-size: 0.8em;
  width: 60px;
}

#measurement-unit-details dd {
  margin-bottom: 0.5em;
}


div.dhtmlx_window_active, div.dhx_modal_cover_dv {
  position: fixed !important;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu:hover > .dropdown-menu > div:first-of-type::before,
.dropdown-submenu:hover > .dropdown-menu > li:first-of-type > a::before {
  display: block;
  content: " ";
  float: left;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 5px 5px 0px;
  border-right-color: #ccc;
  margin-top: 5px;
  margin-left: -10px;
}

.dropdown-submenu:hover > .dropdown-menu > div:first-of-type::before {

  margin-top: 8px;
}

.dropdown-submenu:hover > .dropdown-menu:hover > div:first-of-type::before,
.dropdown-submenu:hover > .dropdown-menu:hover > li:first-of-type > a::before {
  border-right-color: #f00;
}

.dropdown-submenu > .dropdown-menu > div.famoca-submenu-inline-container {

  display: flex;
  justify-content: space-around;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #f00;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.famoca-scada-config-modal.target-attachment-right > .modal-dialog {
  margin-right: 1%;
}

.famoca-scada-config-modal.target-attachment-left > .modal-dialog {
  margin-left: 1%;
}

.ember-modal-overlay.famoca-modal-total-overlay {
  /* soll die Navbar überdecken*/
  z-index: 1050;
}

.ember-power-select-dropdown.famoca-power-select-double-length-dropdown .ember-power-select-options {

  max-height: 28em;
}

.ember-power-select-dropdown.famoca-dropdown-in-modal {
  z-index: $famoca-dropdown-in-modal-z-index;
}

.ember-power-select-trigger--famoca-as-form-control-sm:is(.ember-power-select-trigger)  {
  @extend .form-control-sm;
  @extend .form-control;
  &.ember-power-select-multiple-trigger {
    height: unset;
  }
  & .ember-power-select-selected-item,
  & .ember-power-select-placeholder {
    margin-left: 0;
  }
}

.famoca-accordion-item-body {
  max-height: 200px;
  overflow-y: auto;
}

.famoca-container-for-scrollable-table-fixed-headers > table > thead th {

  position: sticky;
  top: 0;
  background-color: gainsboro;
}

.famoca-proxy-tree-item-link {
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.narrow {
    max-width: 60%;
  }
  &.fullwidth {
    max-width: 100%;
  }
}

.famoca-proxy-tree-item-link,
.famoca-facility-item-link {

  display: inline-block;
}

/* zu BS text-truncate */
.famoca-show-on-hover:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}


.famoca-accordion-link {
  overflow-x: hidden;
}

.famoca-datapoint-current-value-display {
  &.highlight {
    outline: orange 1px solid;
  }
  &.highlight-success {
    outline: #2ecc40 1px solid;
  }
  &.highlight-failure {
    outline: red 1px solid;
  }
}

.famoca-proxy-tree-item-link a.disabled,
.famoca-accordion-link a.disabled,
.famoca-facility-item-link a.disabled {

  pointer-events: none;
  color: #aaa;
  text-decoration: none;
}

.famoca-proxy-tree-item-link a.active,
.famoca-accordion-link a.active,
.famoca-facility-item-link a.active {

  font-weight: bold;
}

.famoca-proxy-tree-item-link a.active {
  //background-color: #F4FF77;
  //text-decoration: underline;
}

.famoca-proxy-tree-subtree-link {
  padding: 0.2em;
  text-decoration: none;
  flex-shrink: 0;

  &:hover {
    text-decoration: none;
  }

  &.active span {
    outline: orange solid 2px;
  }

  padding-right: 10px;
}

.btn.famoca-analysis-settings-refresh-required {
  position: relative;
  &::after {
    position: absolute;
    top: 0;
    left: 100%;
    color: black;
    background-color: #007bff;
    padding: 0.25em;
    border-radius: 50%;
    border: 1px solid lightgrey;
    transform: translate(-50%, -50%);
    content: '';
  }
}

.btn.famoca-button-download {

  background-color: darkgrey;
  color: white;
}

.btn.famoca-button-download:focus,
.btn.famoca-button-download:hover,
.btn.famoca-button-download + .btn.famoca-button-download:hover,
.btn.famoca-button-download + .btn.famoca-button-download:focus {

  color: white;
  background-color: dimgrey;
  border-color: black;
}

.btn.famoca-button-download + .btn.famoca-button-download {

  border-left-color: lightgrey;
}

.c3-target-famoca-c3-threshold-warning path {

  stroke-width: 2px;
  stroke-dasharray: 5, 4, 3, 4;
}

.c3-target-famoca-c3-threshold-alarm path {

  stroke-width: 2px;
  stroke-dasharray: 5, 1;
}

.famoca-live-data-page-menu-grip-border {
  position:fixed;
  top:0vh;
  bottom:0px;
  left:-10px;
  width:20px;
  z-index: 2; 
  //background-color: #cccccc;
  border-left: 10px solid #cccccc;
}

.famoca-live-data-page-menu-grip-border:hover {
  left:0px;
}

.famoca-live-data-page-menu-grip {

  top: 60vh;
  position: relative;
  left: 0px;
  width: 0px;
  height: 100px;
  border-left: 16px solid #cccccc;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: none;
}

.famoca-live-data-page-menu-grip-attached {

  @extend .famoca-live-data-page-menu-grip;
  top: calc(60vh - #{$famoca-bm-live-data-top});
  left: 300px;
}

.famoca-default-layout-resize-bar {
  cursor: e-resize;
  display: flex;
  width: 15px;
  z-index: 2;
  justify-content: center;
  align-items: center;
  &.famoca-default-layout-resize-bar-collapsed {
    position: absolute;
    left: 0;
    height: calc(100vh - 90px);
    &:hover {
      border-left: 4px solid lightgray;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }
  & > div {
    border-left: 15px solid lightgray;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    height: 100px
  }

}

.famoca-default-layout-resize-bar.active {
  & > div {
    border-left: 15px solid gray;
  }
  &.famoca-default-layout-resize-bar-collapsed {
    border-left: 4px solid gray;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }
}

.famoca-scheduler-event-workbench-container-area {

  display: block;
  overflow: hidden;
  max-height: 200px;

  transition-property: max-height;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  &.left-out, &.right-out {

    max-height: 0px;
    transition-delay: 0.5s;
  }

  &.left-start {

    max-height: 0px;
    transition-delay: 0s;
  }
}

.famoca-scheduler-event-workbench-container {

  overflow: hidden;
}

.famoca-scheduler-event-workbench {

  margin-left: 0px;
  margin-right: 0px;
  transition-property: margin-left, margin-right;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
  transition-delay: 0.2s;

  &.left-start {

    margin-left: -2000px;
    margin-right: 2000px;
    transition: 0s;
  }

  &.left-out {

    margin-left: -2000px;
    margin-right: 2000px;
    transition-property: margin-left, margin-right;
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    transition-delay: 0s;
  }

  &.right-out {

    margin-left: 2000px;
    margin-right: -2000px;
    transition-property: margin-left, margin-right;
    transition-duration: 0.4s;
    transition-timing-function: ease-in;
    transition-delay: 0s;
  }
}

.famoca-scheduler-event-workbench-controls {

  transition-property: visibility;
  transition-delay: 0.7s;
}

.famoca-control-group-handle.max {

  transition-property: transform, visibility;
  transition-duration: 0.1s, 0s;
  transition-delay: 0s;
  transition-timing-function: ease-out;
}

.famoca-control-group-handle.min {

  transition-property: transform, visibility;
  transition-duration: 0.1s, 0s;
  transition-delay: 0s, 0.1s;
  transition-timing-function: linear;
}

input.famoca-dropdown-list-search {
  z-index: 1051;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
}

input.famoca-checkbox-list-select {
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.famoca-error-details-list {

  padding: 1em;
  background-color: white;
  color: initial;
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  border-color: lightgrey;
  max-height: 300px;
  overflow-y: auto;
}

.famoca-flip-container {

  perspective: 4000px;
}

.famoca-flip-container.flipped .famoca-flip-flipper {

  transform: rotateY(180deg);
}

.famoca-flip-flipper {

  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.famoca-flip-frontside, .famoca-flip-backside {

  backface-visibility: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.famoca-flip-frontside {
  z-index: 2;
  transform: rotateY(0deg);
}

.famoca-flip-backside {
  transform: rotateY(180deg);
}

.famoca-highlight-cell {

  outline: orange solid 2px;
}

.famoca-highlight-item {

  border: orange solid 1px;
}

.famoca-scheduler-event-workbench-controls.invisible {

  transition-property: visibility;
  transition-delay: 0s;
}

.famoca-link-to-hidden-if-active.active {

  visibility: hidden;
}

.famoca-link-to-if-privileged.disabled {

  text-decoration: none;
  color: inherit;
  cursor: default;
}

.famoca-link-to-if-privileged-navlink > span:nth-child(2) {

  margin-left: 10px;
}

.famoca-mixin-btn-group-right.btn-group {

  display: flex;
  justify-content: flex-end;
}

@media (max-width: $famoca-hide-icon-breakpoint) {
  .famoca-hide-below-breakpoint {
    display: none;
  }
}

@media (max-width: 1400px) and (min-width: 1200px){
  .famoca-navbar-icon {
    display:none;
  }

  .famoca-navbar-username {
    display:none;
  }
}

.famoca-navbar {
  padding-left: 0px;
}

.famoca-navbar-brand-image-enimon,
.famoca-navbar-brand-image-enervision {

  line-height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  & > img {
    max-height: 51px;
  }
}

img.famoca-brand-logo {
  content: var(--product_logo_path);
}

.famoca-output-balancingComment {

  white-space: pre-wrap;
  overflow-y: auto;
  min-height: 174px;
  max-height: 174px;
  display: block;
  border: 1px solid darkgrey;
}

.famoca-output-balancingComment-maxi {

  white-space: pre-wrap;
  overflow-y: auto;
  min-height: 8.5em;
  max-height: 8.5em;
  display: block;
  border: 1px solid darkgrey;
}

.famoca-panel-with-navtabs.panel > .panel-heading {

  padding: 5px 5px 0 5px;
}

.famoca-panel-with-navtabs.panel .nav-tabs {
  border-bottom: none;
}

.famoca-override-alert-as-panel-footer {
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.famoca-override-radio.radio,
.famoca-override-checkbox.checkbox {

  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.famoca-override-radio.radio + .famoca-override-radio.radio,
.famoca-override-checkbox.checkbox + .famoca-override-checkbox.checkbox {

  margin-top: -5px;
}

.famoca-override-radio.radio input[type="radio"],
.famoca-override-checkbox.checkbox input[type="checkbox"] {

  margin-left: -20px;
  position: absolute;
}

.famoca-running-dashed-border-top {

  display: block;
  width: 200%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top: black 2px dashed;
  animation: slideWestEast 2.5s infinite linear;
}

.famoca-running-dashed-border-right {

  display: block;
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  border-right: black 2px dashed;
  animation: slideNorthSouth 2.5s infinite linear;
}

.famoca-running-dashed-border-bottom {

  display: block;
  width: 200%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: black 2px dashed;
  animation: slideEastWest 2.5s infinite linear;
}

.famoca-running-dashed-border-left {

  display: block;
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  border-left: black 2px dashed;
  animation: slideSouthNorth 2.5s infinite linear;
}

input.form-control.famoca-scada-input-scada-dim-position {

  width: 6em;
}

.famoca-scada-page-tree {

  overflow-y: auto;
  height: 90%;
}

.famoca-scada-page-tree-item {

  display: flex;
  justify-content: space-between;
}

.famoca-text-dark-orange {
  color: darkorange !important;
}

.famoca-text-unfilterable {

  font-style: italic;
  opacity: 0.7;
}

.famoca-table-text-sideband {

  font-style: italic;
  opacity: 0.7;
  font-size: smaller;
  background-color: transparent;
}

.famoca-table-error caption {

  color: orangered;
  background-color: lightpink;
}

tr.famoca-table-row-top-aligned > td {
  vertical-align: top;
}

.famoca-text-flash {
  animation-name: flashText;
  animation-duration: 200ms;
  animation-direction: normal;
  animation-fill-mode: none;
}

@keyframes slideWestEast {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slideEastWest {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes slideNorthSouth {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes slideSouthNorth {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-50%);
  }
}

@keyframes flashText {
  50% {
    color: orange;
  }
  100% {
    color: red;
    font-size: larger;
  }
}

.famoca-timeswitch-event {

  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.famoca-timeswitch-event-title {

  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
}

span.asyncSpinner {
  position: relative;
  display: inline-block;
  margin-left: .3em;
  margin-right: .6em;
  padding-bottom: .6em;
}

.famoca-visualization-options-navtab-content,
.famoca-functional-template-properties-navtab-content,
.famoca-functional-template-datapoints-navtab-content,
.famoca-functional-template-functional-units-navtab-content {

  background-color: #f5f5f5;
  border: solid 1px #ddd;
  border-radius: 4px;
  margin-bottom: 5px;
}

.famoca-functional-template-properties-navtab-content,
.famoca-functional-template-datapoints-navtab-content,
.famoca-functional-template-functional-units-navtab-content {

  background-color: inherit;
}

.famoca-info-matrix-table {
  font-size: 0.8rem;
}

.famoca-info-matrix-table th[scope="row"] {

  text-align: right;
  background-color: #E1EEF4;
}

.famoca-info-matrix-table th[scope="col"] {

  background-color: #e6e6e6;
}

.famoca-system-settings-table tr.warning > td {

  background-color: #fcf8e3;
}

.famoca-lamp-status-matrix-table {
  font-size: 0.8rem;
  --table-border-width: 1px;

  th[scope="col"] {

    height: 10rem;
    border: none;
    position: relative;
    top: 0;
    &.rotate {
      white-space: nowrap;

      & > div {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translate(calc(100% - var(--table-border-width) / 2), var(--table-border-width)) rotate(315deg);
        transform-origin: 0% calc(100% - var(--table-border-width));

        & > span {
          position: absolute;
          bottom: 0;
          left: 0;
          border-bottom: var(--table-border-width) solid #dee2e6;
        }
      }
    }
  }
  th[scope="row"] {
    //background-color: #E1EEF4;
    background-color: rgba(230,150,120,0.1);
  }
  tbody td {
    text-align: center;
  }
  tbody td.last-fill {
    min-width: 3rem;
  }

}

.famoca-structure-nodes-tree {

  max-height: 200px;
  overflow-x: auto;
  overflow-y: auto;
}

.famoca-rules-structure-nodes-tree {

  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
}

.famoca-structure-nodes-tree-for-control-units .famoca-structure-nodes-tree {

  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
}

.famoca-main-navigation-tree,
.famoca-structure-nodes-tree-max {

  font-size: medium;
  background-color: #f5f5f5;
}

.famoca-main-navigation-tree .famoca-main-navigation-tree-body {
  height: 50%;
  overflow-x: auto;
  overflow-y: auto;
}

.famoca-structure-nodes-tree-max .site-structure-nodes-tree-body {

  max-height: 60vh;
  overflow-x: auto;
  overflow-y: auto;
}

.famoca-main-navigation-tree .famoca-main-navigation-tree-header,
.famoca-structure-nodes-tree-max .site-structure-nodes-tree-header {
  height: 50%;
  padding: 30px;
  overflow-y: auto;
}

.famoca-lon-elements-tree {

  max-height: 40vh;
  overflow: auto;
}

.famoca-main-navigation-tree code,
.famoca-structure-nodes-tree-max code,
.famoca-lon-elements-tree code {

  background-color: inherit;
}

.famoca-visualization-navtabs {
  // ermöglicht vertikales Überlaufscrolling im .tab-pane bei definierter, äußerer Höhe
  flex-grow: 1;
  &,
  & .tab-content,
  & .tab-content .tab-pane.active {

    display: flex;
    flex-direction: column;
    min-height: 0px;
  }
}

input.famoca-hex-byte {

  min-width: 3em;
}

input.famoca-input-succeeded {

  border-style: solid;
  border-color: green;
}



.nav-link.text-dark,
.dropdown-toggle.nav-link {
  line-height: 20px;
  padding-top: 16px;
  padding-bottom: 15px;
}

.scada-datapoint-assignments-list {

  overflow-y: auto;
  height: 75%;
  padding-right: 2em;
}

.scada-datapoint-assignments-list li::marker {

  font-weight: bold;
  font-size: large;
}

.scada-group-index-overlay {

  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -18px;
  left: -17px;
  border-radius: 10px;
  border-style: solid;
  border-color: blue;
  border-width: 2px;
  color: blue;
  transition: color 0.5s, border-color 0.5s;
}

.scada-group-index-overlay.list-hover {

  color: gold;
  border-color: gold;
}

.scada-slide-in-image {

  backface-visibility: hidden;
  perspective: 1000px;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  transition-property: left, top;
}


.scada-slide-in-image.selection-highlighting {

  outline-color: blue;
  outline-style: dotted;
  outline-width: thin;
  transition: outline-color 0.5s;

  &.assignment-set-highlighting {
    outline-color: mediumspringgreen;
    outline-width: thick;
  }

  &.group-highlighting {
    outline-color: red;
    outline-style: dashed;

    &.assignment-set-highlighting {
      outline-color: mediumspringgreen;
      outline-width: thick;
    }
  }
}

.scada-slide-in-image.group-highlighting.list-hover,
.scada-slide-in-image.selection-highlighting.list-hover {

  outline-color: gold;
  outline-style: solid;
  outline-width: thick;
}


textarea[name='famoca-textarea-analysisComment'] {

  max-width: 100%;
  min-width: 100%;
  min-height: 4ex;
  width: 100%;
}

textarea[name='famoca-textarea-balancingComment'] {

  min-height: 65px;
  width: 100%;
  resize: vertical;
  /*hält den Textbereich auch über nachfolgende Glyphicons mit z-index 0 im Vordergrund:*/
  position: relative;
  z-index: 1;
}

table > tbody > tr > td.famoca-table-cell-medium,
table > tbody > tr > th.famoca-table-cell-medium {

  border-right: none;
}

table > tbody > tr > th.famoca-table-cell-medium.warning {

  background-color: #ffffcc;
}

table > tbody > tr > td.famoca-table-cell-medium-empty {

  border: none;
}

table > tbody > tr > td.famoca-table-cell-medium-comment {

  overflow-x: hidden;
  white-space: normal;
  border: none;
  font-weight: bold;
}

tr.famoca-table-row-nowrap > td,
tr.famoca-table-row-nowrap > th {
  white-space: nowrap;
}

tr.famoca-table-row-linkto {
  &.active {
    background-color: #F4FF77;
  }
}

td.famoca-table-cell-node-grey,
th.famoca-table-cell-node-grey {
  background-color: #f5f5f5;
}

.table > tbody > tr > td.famoca-table-cell-node-grey.warning {
  background-color: #ffffcc;
}

.table > thead > tr > th.famoca-table-header-darkgrey {
  background-color: grey;
  color: white;
  vertical-align: middle;
}

.famoca-navtabs-right ul > li.famoca-navtab {

  float: right;
}

.nav label.file-upload {

  font-weight: normal;
}

.nav a label.file-upload {

  cursor: pointer;
}

.nav-tabs > li.famoca-navtab.active > a,
.nav-tabs > li.famoca-navtab.active > a.active,
.nav-tabs > li.famoca-navtab.active > a:hover {

  background-color: #f5f5f5;
}

.nav-tabs > li.famoca-navtab > a:hover {

  background-color: initial;
}

dl.dl-wide dt {
  width: 200px;
}

dl.dl-wide dd {
  margin-left: 240px;
}

div.option.disabled {

  pointer-events: none;
  color: #aaa;
}

.form.align-vertically {
  display: flex;
  align-items: center;
}

.famoca-tree-node-trigger-expand {
  flex-shrink: 0
}

ul.famoca-tree-custom,
ul.famoca-tree-custom ul.famoca-tree {

  list-style: none;
}

ul.famoca-tree li {
  &:focus-visible {
    outline: none;
  }
}

ul.famoca-tree-custom ul.famoca-tree:not(:last-of-type) > div > ul.famoca-tree,
ul.famoca-tree-custom ul.famoca-tree:not(:last-of-type) > div > div.infinite-scroller > ul.famoca-tree,
ul.famoca-tree-custom ul.famoca-tree:not(:last-of-type) > div > div.infinite-scroller > .show-more-children-button,
ul.famoca-tree-custom:not(:last-of-type) > div > ul.famoca-tree,
ul.famoca-tree-custom:not(:last-of-type) > div > div.infinite-scroller > ul.famoca-tree,
ul.famoca-tree-custom:not(:last-of-type) > div > div.infinite-scroller > .show-more-children-button {

  border-left: 1px dotted;
}

ul.famoca-tree-custom {
  padding-left: 0;
}

ul.famoca-tree-custom ul.famoca-tree,
ul.famoca-tree-custom a.show-more-children-button
{
  margin-left: 4px;
  padding-left: 1.2em;
}

ul.famoca-tree-custom:not(:last-of-type) {

  margin-bottom: 0;
}

.well.famoca-well-success {
  /* wie BS alert-success */
  background-color: #dff0d8;
}

a.famoca-tree-custom {
  color: grey;
}

a.famoca-tree-custom:hover {
  color: inherit;
  text-decoration: none;
}

@media print {

  .details-box {
    border: none;
    padding: 0;
    margin: 0;
  }
  table td span.text-danger {
    color: #a94442 !important;
  }
  table > tbody > tr > td.famoca-table-cell-medium.success,
  table > tbody > tr > th.famoca-table-cell-medium.success {

    border-right: none !important;
    background-color: #dff0d8 !important;
  }
  table > tbody > tr > td.famoca-table-cell-medium-empty.success {

    border: none !important;
    background-color: #dff0d8 !important;
  }
  table > tbody > tr > td.famoca-table-cell-node.info {

    background-color: #d9edf7 !important;
  }
}
