$grid-float-breakpoint: 920px;
$famoca-hide-icon-breakpoint: 1460px;
$famoca-bm-live-data-top: 51px;
$navbar-padding-y: 0;
@import "ember-bootstrap/bootstrap";
@import "ember-power-select";
$famoca-dropdown-in-modal-z-index: $zindex-modal + 1; // 1 über Bootstrap Modal
@import './famoca';

/* Ember Burger-Menu */
$bm-overlay-background: rgba(0, 0, 0, 0.0);
.ember-burger-menu .bm-menu {
  background: #cccccc;
  padding: 1em;
}
.ember-burger-menu.multiple {
  position: absolute;
  top: $famoca-bm-live-data-top;
  height: calc(100vh - #{$famoca-bm-live-data-top}); // Navigationsmenü und GLT-Menüs; maximal Ausdehnung bis zu unterem Fensterrand
  width: 100%;
  overflow: hidden;
  margin-left: -8px; //→ body margin
  margin-right: -8px;
}
@import 'ember-burger-menu/variables';
@import 'ember-burger-menu/structure';
@import 'ember-burger-menu/animations/slide';

.task-tooltip {
  width: 600px !important;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
  left: -400px !important;
}
.task-tooltip-inner {
  background-color: white;
  text-shadow: none !important;
  width: 600px !important;
  color: black;
  border: 2px solid #F4FF77;
}

.task-tooltip-arrow {
  border: transparent;
}
